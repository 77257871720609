<template>
  <div class="page-link-code">
    <button class="nav-btn" @click="$router.push('/link_code/by_code')">По штрихкоду</button>
    <button class="nav-btn" @click="$router.push('/link_code/by_goods')">По товару</button>
  </div>
</template>

<script>
export default {
  setup(props, {emit}) {
    emit('meta', {title: 'Привязка кодов', back: '/'})
  }
}
</script>

<style lang="less">
.page-link-code {
  .nav-btn {
    display: block;
    width: calc(100% - 20px);
    margin: 10px;
  }
}
</style>
